import React from "react";
import { useForm } from "react-hook-form";
import { createEpisode } from "../../Services/Service";
import { toast } from "react-toastify";

export default function Admin() {
  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    createEpisode(data)
      .then((response) => {
        console.log(response.data.message);
        console.log(response);
        if(response.data.status === "true"){
          toast.success("Record added successfully", {
            position: "top-center",
            autoClose: 5000,
          })
          reset();
        }else{
          toast.warning("There might be some error while adding new data", {
            position: "top-center",
            autoClose: 5000,
          })
        }
      })
      .catch((error) => {
        if(error){
          console.log(error);
          toast.warning("There might be some error while adding new data TRY CatcH", {
            position: "top-center",
            autoClose: 5000,
          })
        }  
      });
  };
  return (
    <div>
      <section class="vh-100 mt-5 pt-5" style={{ backgroundColor: "#eee" }}>
        <div class="container h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-lg-12 col-xl-11">
              <div class="card text-black" style={{ borderRadius: "25px" }}>
                <div class="card-body p-md-5">
                  <div class="row justify-content-center">
                    <div class="col-md-10 col-lg-8 col-xl-6 order-2 order-lg-1">
                      <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">
                        Create Episode
                      </p>

                      <form
                        class="mx-1 mx-md-4"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                       
                        <div class="d-flex flex-row align-items-center mb-2">
                          <i class="fas fa-envelope fa-lg me-3 fa-fw"></i>
                          <div class="form-outline flex-fill mb-0">
                          <label class="form-label" for="form3Example3c">
                              Title
                            </label>
                            <input
                              type="text"
                              id="form3Example3c"
                              class="form-control"
                              name="title"
                              {...register("title", {
                                required: "Title is required",
                              })}
                            />
                            
                          </div>
                        </div>
                        <div>
                            <p className="ms-3 text-danger">
                              {errors.title?.message}
                            </p>
                          </div>

                        <div class="d-flex flex-row align-items-center mb-2">
                          <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                          <div class="form-outline flex-fill mb-0">
                          <label class="form-label" for="form3Example4c">
                              Description
                            </label>
                            <textarea
                              type="text"
                              id="form3Example4c"
                              class="form-control"
                              name="description"
                              {...register("description", {
                                required: "Description is required",
                              })}
                            />
                           
                          </div>
                        </div>
                        <div>
                            <p className="ms-3 text-danger">
                              {errors.description?.message}
                            </p>
                          </div>

                          <div class="d-flex flex-row align-items-center mb-2">
                          <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                          <div class="form-outline flex-fill mb-0">
                          <label class="form-label" for="form3Example4c">
                              Link
                            </label>
                            <input
                              type="text"
                              id="form3Example4c"
                              class="form-control"
                              name="link"
                              {...register("link", {
                                required: "Link is required",
                              })}
                            />
                           
                          </div>
                        </div>
                        <div>
                            <p className="ms-3 text-danger">
                              {errors.link?.message}
                            </p>
                          </div>

                        <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                          <button type="submit" className="btn btn-success">
                            Create
                          </button>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-10 col-lg-4 col-xl-6 d-flex align-items-center order-1 order-lg-2">
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                        class="img-fluid"
                        alt="Sample image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
