import React from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Form from "../Extra/Form";

function Contact() {
  const {
    register,
    handleSubmit,
    getValues,

    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    axios
      .post("http://localhost:8080/register", data)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <main id="main">
        <section class="intro-single">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-8">
                <div class="title-single-box">
                  <h1 class="title-single">Contact US</h1>
                  {/* <span class="color-text-a">
                    Aut voluptas consequatur unde sed omnis ex placeat quis eos.
                    Aut natus officia corrupti qui autem fugit consectetur quo.
                    Et ipsum eveniet laboriosam voluptas beatae possimus qui
                    ducimus. Et voluptatem deleniti. Voluptatum voluptatibus
                    amet. Et esse sed omnis inventore hic culpa.
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="contact">
          <div class="container">
            <div class="row">
              <div class="col-sm-12">
                <div class="contact-map box">
                  <div id="map" class="contact-map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d237.9062763836089!2d-112.13391966370443!3d33.63028957228134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b6f006710a49d%3A0x9c3ed0025e8646a4!2s15830%20N%2035th%20Ave%20%233%2C%20Phoenix%2C%20AZ%2085053%2C%20USA!5e1!3m2!1sen!2sin!4v1662805964482!5m2!1sen!2sin"
                      width="100%"
                      height="450"
                      frameBorder="0"
                      style={{ border: "0" }}
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 section-t8">
                <div class="row">
                  <div class="col-md-7 border-left-0">
                    <Form/>
                    {/* <form onSubmit={handleSubmit(onSubmit)}>
                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <div class="form-group">
                            <input
                              type="text"
                              name="Name"
                              class="form-control form-control-lg form-control-a"
                              placeholder="Your name"
                              {...register("Name", {
                                required: "Name is required",
                              })}
                            />
                          </div>
                          <div>
                            <p className="mt-3 text-success">
                              {errors.Name?.message}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-6  mb-3">
                          <div class="form-group">
                            <input
                              type="email"
                              name="email"
                              class="form-control form-control-lg form-control-a"
                              placeholder="Email"
                              {...register("email", {
                                required: "Email is required",
                              })}
                            />
                          </div>
                          <div>
                            <p className="text-success">
                              {errors.email?.message}
                            </p>
                          </div>
                        </div>

                        <div class="col-md-12 mb-3">
                        <div class="form-group">
                            <input
                              type="text"
                              name="Subject"
                              class="form-control form-control-lg form-control-a"
                              placeholder="Subject"
                              {...register("Subject", {
                                required: "Subject is required",
                              })}
                            />
                          </div>
                          <div>
                            <p className="mt-3 text-success">
                              {errors.Subject?.message}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <textarea
                              name="message"
                              class="form-control"
                              cols="45"
                              rows="8"
                              placeholder="Message"
                            ></textarea>
                          </div>
                        </div>
                        

                        <div class="col-md-12 mt-5 text-center">
                          <button type="submit" class="btn btn-lg btn-success">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form> */}
                  </div>
                  <div class="col-md-5 section-md-t3">
                    <div class="icon-box section-b2">
                      <div class="icon-box-icon">
                        <span class="bi bi-envelope"></span>
                      </div>
                      <div class="icon-box-content table-cell">
                        <div class="icon-box-title">
                          <h4 class="icon-title">Say Hello</h4>
                        </div>
                        <div class="icon-box-content">
                          <p class="mb-1">
                            Email: 
                            <span class="color-a ps-2">
                            <a href="mailto:info@brepm.com">info@brepm.com</a>
                            </span>
                          </p>
                          <p class="mb-1">
                          Phone:
                          <span class="color-a ps-2"> 
                      <a href="tel:6234714451">623-471-4451</a>
                      </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="icon-box section-b2">
                      <div class="icon-box-icon">
                        <span class="bi bi-geo-alt"></span>
                      </div>
                      <div class="icon-box-content table-cell">
                        <div class="icon-box-title">
                          <h4 class="icon-title">Find us in</h4>
                        </div>
                        <div class="icon-box-content">
                          <p class="mb-1">
                          15830 N. 35th <br /> AVE STE 3 Phoenix, <br /> AZ 85053
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Contact;
