import React from 'react';
import axios from "axios";
import { useEffect } from 'react';

function Blog() {
  useEffect(() => {
    axios.get("https://www.simplifyingthemarket.com/en/feed/?a=500070-ab14bdd7fe835090a3b233776113bb76").then((response) => {
      console.log(response)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
        <h1>This is Blog Component</h1>
    </div>
  )
}

export default Blog