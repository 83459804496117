const sampleData = [
  {
    id: 1,
    title: " The Right Home, At The Right Time, For The Right Buyer",
    link: "https://www.youtube.com/embed/-C89xNchGEQ",
    description:
      "Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.  If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! We want to feature your tidbits and stories please send them in: tidbits@brepm.com Even more, we'd love to have you on to share your 'tidbit'. Please share all your tips, crazy experiences, awesome or annoying things only we Real Estate Professionals can appreciate. Thanks!",
  },
  {
    id: 2,
    title: "Find Your Why Pt 1",
    link: "https://www.youtube.com/embed/JoLHLay_TTA",
    description:
      "Todays tidbit is finding your why. It's important to find your why. It's also important to know that over time your why may change. Your why may be your career or it may be what your career allows you to do. Todays message changed my life. Check it out.      Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.  If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! We want to feature your tidbits and stories please send them in: tidbits@brepm.com Even more, we'd love to have you on to share your 'tidbit'. Please share all your tips, crazy experiences, awesome or annoying things only we Real Estate Professionals can appreciate. Thanks! ",
  },
  {
    id: 3,
    title: "Find your Why Pt 2",
    link: "https://www.youtube.com/embed/7gGOC2v5EWI",
    description:
      "Todays tidbit continues yesterdays thought, the importance of having your 'why' and how it will change your life.  I shared the 'why' that got me into Real Estate, which then allowed me to find and follow my passions. Now, 10 plus years later, my 'why' has changed, which also allowed my passions to grow and  create. This is why Breakthrough is in existence today! Amazing! \n Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. \nIf you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: 4,
    title: "Follow Up Week",
    link: "https://www.youtube.com/embed/Xo1p_5R3AZo",
    description:
      "This week we are focusing on follow up. Starting off with todays episode on internet leads. This is where most agents are getting their leads today, so I figured let's start here. Of course speed to lead is where it begins, but where do we go from there and how. All in 3 minutes, boom! <br/>Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: 5,
    title: "Lead Follow Up Week",
    link: "https://www.youtube.com/embed/3tTSvdKrRC4",
    description:
      "Day 2 of Lead Follow Up Week is on Open House Leads. How do you rock them? It all starts at the Open.Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit Lastly, we would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com",
  },
  {
    id: "6",
    title: "Follow Up Plan Week- Sphere",
    link: "https://www.youtube.com/embed/vriOFs356AM",
    description:
      "In todays episode we are continuing our Follow Up Plan Week and going over Sphere of Influence. More is More in this case. Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit Lastly, we would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com",
  },
  {
    id: "7",
    title: "Follow Up Week- FSBO's",
    link: "https://www.youtube.com/embed/N8JkVVqkQuA",
    description:
      "In todays episode we are continuing our Follow Up Plan Week and going over FSBO's. These scare most agents... which is exactly why they are perfect.Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit. Lastly, we would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com",
  },
  {
    id: "8",
    title: "Lead Follow Up Week- Expired's",
    link: "https://www.youtube.com/embed/3H8qIgYkeBo",
    description:
      "In todays episode we are continuing our Follow Up Plan Week and going over Expired's. Most agents are scared of these, spych themselves out and don't follow up properly when they do. Which is why these are an untapped goldmine.Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit Lastly, we would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com",
  },
  {
    id: "9",
    title: "Stick-to-it-iveness",
    link: "https://www.youtube.com/embed/qDG2Zj5duwI",
    description:
      "In todays episode we are going over the power, importance and benefits of 'Stick-to-it-iveness'. Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit Lastly, qe would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com",
  },
  {
    id: "10",
    title: "1st Day or 6 Months, It's Worth It",
    link: "https://www.youtube.com/embed/7VojPGmWFQA",
    description:
      "Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit Lastly, qe would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com",
  },
  {
    id: "11",
    title: "Assume The 'Happy'",
    link: "https://www.youtube.com/embed/7uepBoz1j28",
    description:
      "In todays episode we are pointing out a HUGE benefit of Assuming the 'Happy'.Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit Lastly, qe would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com",
  },
  {
    id: "12",
    title: "Optimism Is The Answer",
    link: "https://www.youtube.com/embed/OCDzXS26zGo",
    description:
      "In todays episode we share why Optimism is Always the answer. Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit Lastly, we would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com",
  },
  {
    id: "13",
    title: "Deep Net= Rock Star",
    link: "https://www.youtube.com/embed/j2DFGiAplc0",
    description:
      "In todays episode we talk about why a deeper net will make you a rock star. Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit Lastly, qe would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com",
  },
  {
    id: "14",
    title: "The Right Home Is The Right Home",
    link: "https://www.youtube.com/embed/VqJXqDeztL4",
    description:
      "In todays episode we are reiterating why the Right Home is the Right Home, no matter what. Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit Lastly, qe would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com ",
  },
  {
    id: "15",
    title: "3 Hours and 6 Days",
    link: "https://www.youtube.com/embed/mIWMMqKUWU0",
    description:
      "One of my fav experiences as an agent so far! Daily Tidbit Episode 15 is about always offering great service to your clients and all the pleasant surprises that come along with it. Some of the things our clients do surprise us in the best ways possible. Love this career! Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated! Checkout out past episodes: www.BreakthroughRealEstateAZ.com/dailytidbit Lastly, qe would love to feature YOU and your great ideas, stories or tidbits, please share them with us: tidbit@brepm.com ",
  },
  {
    id: "16",
    title: "Uh Oh, Is This Lender TRUSTWORTHY?",
    link: "https://www.youtube.com/embed/WPG3bjXut7s",
    description: "",
  },
  {
    id: "17",
    title: "Are You A Secret Agent?",
    link: "https://www.youtube.com/embed/nbfEm3N12pc",
    description: "",
  },
  {
    id: "18",
    title: "Do You Have A Strong Table Top?",
    link: "https://www.youtube.com/embed/88O_2LBBMko",
    description: "",
  },
  {
    id: "19",
    title: "Are You Dripping All Over?",
    link: "https://www.youtube.com/embed/JUKwMb9nJqM",
    description: "",
  },
  {
    id: "20",
    title: "What's Your Cause?",
    link: "https://www.youtube.com/embed/4uYzGkE5tfA",
    description: "",
  },
  {
    id: "21",
    title: "The 'Just In Case' Business Plan",
    link: "https://www.youtube.com/embed/zEOO223zbqA",
    description: "",
  },
  {
    id: "22",
    title: "Have Faith In Your Plan",
    link: "https://www.youtube.com/embed/h2BESo1COXw",
    description: "",
  },
  {
    id: "23",
    title: "The Owner...What?!!",
    link: "https://www.youtube.com/embed/noK8EFvZlPM",
    description: "",
  },
  {
    id: "24",
    title: "Awe, The Days of 2% Interest Rates",
    link: "https://www.youtube.com/embed/OQGbgIL6o9E",
    description: "",
  },
  {
    id: "25",
    title: "Great Things Happen When You Don't Give Up",
    link: "https://www.youtube.com/embed/9kvl0o6xWpA",
    description: "",
  },
  {
    id: "26",
    title: "Quick Tips For Seller Leads",
    link: "https://www.youtube.com/embed/_y42S9MBGfA",
    description: "",
  },
  {
    id: "27",
    title: "Good IS The Biggest Enemy of Great",
    link: "https://www.youtube.com/embed/u-KCM4ajgIo",
    description: "",
  },
  {
    id: "28",
    title: "Do The Work So You Can Let Go",
    link: "https://www.youtube.com/embed/KaYHOIA-XIA",
    description: "",
  },
  {
    id: "29",
    title: "Tips For Getting Buyers Comfortable With An Offer",
    link: "https://www.youtube.com/embed/PSsmHmKf69A",
    description: "",
  },
  {
    id: "30",
    title: "Tips For Sellers Reviewing An Offer",
    link: "https://www.youtube.com/embed/bOn3l0hx_Rk",
    description: "",
  },
  {
    id: "31",
    title: "A Cinderella Story",
    link: "https://www.youtube.com/embed/XAh9amOpuSk",
    description: "",
  },
  {
    id: "32",
    title: "Find An Accountability Partner",
    link: "https://www.youtube.com/embed/JjTCwmxp7Fw",
    description: "",
  },
  {
    id: "33",
    title: "Come Into The Office",
    link: "https://www.youtube.com/embed/SWSbtAMG_wU",
    description: "",
  },
  {
    id: "34",
    title: "What's In A Name?",
    link: "https://www.youtube.com/embed/COjrX-xmMP8",
    description: "",
  },
  {
    id: "35",
    title: "Invest In YOUR Website, NOT someone elses!",
    link: "https://www.youtube.com/embed/wO6fXGZKgDw",
    description: "",
  },
  {
    id: "36",
    title: "Crazy Stories From Showing Homes, 'Were Ready For You'",
    link: "https://www.youtube.com/embed/-Ngi5AwsxEY",
    description: "",
  },
  {
    id: "37",
    title: "Posting What to Where and When",
    link: "https://www.youtube.com/embed/8DmkAtl4qeE",
    description: "",
  },
  {
    id: "38",
    title: "How Much Value Are You Offering?",
    link: "https://www.youtube.com/embed/hiPlCC3Z2Gw",
    description: "",
  },
  {
    id: "39",
    title: "Partners Week Day 1, Escrow/Title Partners",
    link: "https://www.youtube.com/embed/GD2LVJvQmIE",
    description: "",
  },
  {
    id: "40",
    title: "Partners Week Day 2: Lending Partner",
    link: "https://www.youtube.com/embed/05myU9AtYkM",
    description: "",
  },
  {
    id: "41",
    title: "Partners Week Day 3, A Great Inspector Partner",
    link: "https://www.youtube.com/embed/6nJnF7a_zAs",
    description: "",
  },
  {
    id: "42",
    title: "Partners Week Day 4, Home Warranty",
    link: "https://www.youtube.com/embed/_9KV-ZHKLAE",
    description: "",
  },
  {
    id: "43",
    title: "Partners Week Day 5, Have a Mentor!",
    link: "https://www.youtube.com/embed/uMOxxJ8Jpns",
    description: "",
  },
  {
    id: "44",
    title: "Tackle All Your Sources",
    link: "https://www.youtube.com/embed/QZ-KofetDOY",
    description: "",
  },
  {
    id: "45",
    title: "Investment Week Day 1, Single Family Rental Homes",
    link: "https://www.youtube.com/embed/F3-Q8-dlmXk",
    description: "",
  },
  {
    id: "46",
    title: "Investments Week Day 2, Multi Unit Investments",
    link: "https://www.youtube.com/embed/9fwn3gbFLjI",
    description: "",
  },
  {
    id: "47",
    title: "Investments Week Day 3, Fix & Flips",
    link: "https://www.youtube.com/embed/HO_C1UbDzIc",
    description: "",
  },
  {
    id: "48",
    title: "Investments Week Day 4, the 1031 Exchange",
    link: "https://www.youtube.com/embed/_GWndN7xHvo",
    description: "",
  },
  {
    id: "49",
    title: "Investments Week Day 5, Self Directed IRA",
    link: "https://www.youtube.com/embed/MunRj3tgpr4",
    description: "",
  },
  {
    id: "50",
    title: "Objection Handler Series, I'm Just Looking",
    link: "https://www.youtube.com/embed/m2ZdQqvc2Fs",
    description: "",
  },
  {
    id: "51",
    title: "Objection Handler Series, List Price",
    link: "https://www.youtube.com/embed/Abk30mG6IvQ",
    description:
      "In today's episode we are continuing our objection handler series with the 'List Price' Objection.",
  },
  {
    id: "52",
    title: "Objection Handler Series, Will You Cut Your Commission Pt. A",
    link: "https://www.youtube.com/embed/8_JjIbI7oI8",
    description:
      "In today's episode we are are giving you the secret to never cutting your commission, Pt A. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.  If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "53",
    title: "Objection Handler Series, Pt.B",
    link: "https://www.youtube.com/embed/izwV-asRd9Q",
    description:
      "In today's episode we are are finishing up the secrets to never discounting your commission, Pt B. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "54",
    title: "Where Did Buying or Selling A Home Rank In 2018?",
    link: "https://www.youtube.com/embed/CQON4hGBBwU",
    description:
      "In today's episode we are are discussing why buyer or selling a home always ranks as one of the most stressful things people do in their lifetime and why? Where did it rank in 2018? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciate",
  },
  {
    id: "55",
    title: "Get Out of Your Comfort Zone",
    link: "https://www.youtube.com/embed/9kjBU2iwBGI",
    description:
      "In today's episode we are Getting Out of Our Comfort Zone. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "56",
    title: "Things Work Out Best For Those Who...",
    link: "https://www.youtube.com/embed/d4VTXknrFcs",
    description:
      "In today's episode we are covering why 'Things Work Out Best for Those Who Make The Best of How Things Work Out. Thank you John Wooden.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "57",
    title: "Posting What to Where and When",
    link: "https://www.youtube.com/embed/hq17FWcCu9Q",
    description:
      "In today's episode we are discussing the benefits of Having Tunnel Vision Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "58",
    title: "What's Your Tagline of Slogan?",
    link: "https://www.youtube.com/embed/kCYiP2nLczc",
    description:
      "In today's episode we are asking 'What's Your Tagline of Slogan?Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "59",
    title: "The Keys To Repeat Business",
    link: "https://www.youtube.com/embed/wHN7fSLY4Z0",
    description:
      "In today's episode we are are discussing the benefits of having and maintaining relationships.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "60",
    title: "The Truth About Concessions",
    link: "https://www.youtube.com/embed/Z4TvT8EUsZI",
    description:
      "In today's episode we are discussing concessions.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "61",
    title: "More Open House Stories",
    link: "https://www.youtube.com/embed/Fs-zcHkxqsM",
    description:
      "In today's episode we are sharing funny open house stories.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "62",
    title: "More Funny Open House Stories",
    link: "https://www.youtube.com/embed/apSNbWaHzao",
    description:
      "In today's episode we are sharing the story of my first open house. Let's just say, it wasnt a success. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "63",
    title: "Numbers Are The Key To Success, Day 1, Leads",
    link: "https://www.youtube.com/embed/b7GNi8u6rmw",
    description:
      "In today's episode we are going over the importance of knowing your numbers, starting with your leads. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "64",
    title: " NATKTS Day 2, Conversions",
    link: "https://www.youtube.com/embed/nNjMAfD5-NI",
    description:
      "In today's episode we are continuing Numbers Are The Key To Success (NATKTS) Week with Day 2: Conversions Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "65",
    title: "NATKTS Day 3, Appointments",
    link: "https://www.youtube.com/embed/tkeK6_ghm1M",
    description:
      "In today's episode we are continuing Numbera Are Tha Key To Success Day 3 with Appointments. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "66",
    title: "NATKTS Day 4,Contracts",
    link: "https://www.youtube.com/embed/jYX2XcwHP30",
    description:
      "In today's episode we are continuing Numbers Are The Key To Success Week with Day 4 talking Contracts. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "67",
    title: "NATKTS Day 5, Closings",
    link: "https://www.youtube.com/embed/-f5-CyM57jk",
    description:
      "In today's episode we are finishing up Numbers Are The Key To Success Week with CLOSINGS!! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "68",
    title: "Good Ol' Fashioned Customer Service",
    link: "https://www.youtube.com/embed/T07PoZHEl-o",
    description:
      "In today's episode we are talking about Good Ol' Fashioned Customer Service. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "69",
    title: "Do You Make Everyone Around You Look Good?",
    link: "https://www.youtube.com/embed/NDtfMR0jujo",
    description:
      "In today's episode we are sharing the importance of making everyone around you look good. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "70",
    title: "Taking Advantage of Any Great Market",
    link: "https://www.youtube.com/embed/hFpp-1OGmeA",
    description:
      "In today's episode we are are sharing the secret to truly taking advantage of any awesome market. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "71",
    title: "Competition is Really Amazing",
    link: "https://www.youtube.com/embed/d9l74i63jcM",
    description:
      "In today's episode we are sharing why competition is really Amazing! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "72",
    title: "The Range Method For Comps",
    link: "https://www.youtube.com/embed/bJNqN1mGKi4",
    description:
      "In today's episode we are discussing the Range method when doing comps for a listing appointment. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "73",
    title: "Another Take On Competition",
    link: "https://www.youtube.com/embed/JFBvesQFdTs",
    description:
      "In today's episode we are discussing another Take On Competition. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "74",
    title: "Speed To Lead and More",
    link: "https://www.youtube.com/embed/et0dfYEq3Fc",
    description:
      "In today's episode we are discussing more behind the importance of Speed To Lead. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "75",
    title: "$pring Is Here, 3 Tip$ To Make The Mo$t Of It",
    link: "https://www.youtube.com/embed/dxLcwQyoouI",
    description:
      "In today's episode we are taking advantage of Spring! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "76",
    title: "The Importance of Having A Budget",
    link: "https://www.youtube.com/embed/rVFaIty5ezI",
    description:
      "In today's episode we are talking the importance of having a budget. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "77",
    title: "How To Conquer Fear",
    link: "https://www.youtube.com/embed/WE9yhUI7W0I",
    description:
      "In today's episode we are conquering fear.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "78",
    title: "Another Take On Open Houses",
    link: "https://www.youtube.com/embed/jDaCpPxmLis",
    description:
      "In today's episode we are taking another look at open houses. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "79",
    title: "Another Great Open House Story",
    link: "https://www.youtube.com/embed/Qeb7n-_WQIY",
    description:
      "In today's episode you will hear an example of a great open house story. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "80",
    title: "Another GREAT Open House Story",
    link: "https://www.youtube.com/embed/fBfLAdmxX3k",
    description:
      "In today's episode we are exploring another open house story. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "81",
    title: "Time The Market",
    link: "https://www.youtube.com/embed/qQzZKyZszdY",
    description:
      "In today's episode we are discussing timing the market. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "82",
    title: "Timing The Market",
    link: "https://www.youtube.com/embed/QhvGYGV8uEY",
    description:
      "In today's episode we are exploring tips on how to time the market. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "83",
    title: "So Many Niches To Choose From",
    link: "https://www.youtube.com/embed/Dtge0Hx2mlY",
    description:
      "In today's episode we are searching for the right niche. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "84",
    title: "You Are The Expert Who Knows All The Experts",
    link: "https://www.youtube.com/embed/t48l68Z3tdU",
    description:
      "In today's episode we examine the important role you play as the expert who knows all the experts. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "85",
    title: 'Amazing Book Series- "The Servant"',
    link: "https://www.youtube.com/embed/0xxlMyhzkxk",
    description:
      'In today\'s episode we delve into a series on amazing books. We start off with "The Servant." Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "86",
    title: 'Amazing Book Series- "The Compound Effect"',
    link: "https://www.youtube.com/embed/UWHvtvnfrTE",
    description:
      'In today\'s episode we dig into another amazing book. Today we discuss "The Compound Effect." Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "87",
    title: 'Amazing Books Series- "Miracle Morning For Real Estate Agents"',
    link: "https://www.youtube.com/embed/m9YvTeivlu0",
    description:
      'In today\'s episode we are continuing our Amazing Book Series with "Miracle Morning for Real Estate Agents". Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "88",
    title: 'Amazing Book Series- "Grit"',
    link: "https://www.youtube.com/embed/jvRz5cwYAk8",
    description:
      'In today\'s episode we are are continuing our Amazing Book Series with a book called "GRIT". Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "89",
    title: 'Amazing Book Series- "How to Win Friends and Influence People"',
    link: "https://www.youtube.com/embed/oRTyD5xaPso",
    description:
      'In today\'s episode we are continuing our Amazing Book Series with the classic "How to Win Friends and Influence People".Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "90",
    title: "The Compoud Effect Series, Challenge 1",
    link: "https://www.youtube.com/embed/BhlMWBVhwNI",
    description:
      "In today's episode we are starting a new series called the Compound Effect and we will have 1 new challenge using the compound effect each day AND most importantly, moving forward. Let's start with Challenge 1. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "91",
    title: "The Compound Effect, Challenge 2",
    link: "https://www.youtube.com/embed/oDM9qkUNCz0",
    description:
      "In today's episode we are continuing our Compound Effect Challenge Series with Challenge 2. Let's rock this series and keep the compound effect going! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "92",
    title: "The Compound Effect, Challenge 3",
    link: "https://www.youtube.com/embed/4qrx7HbUUlk",
    description:
      "In today's episode we are continuing our Compound Effect Challenge Series with Challenge 3. Keep up the great work! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "93",
    title: "The Compound Effect Challenge Series, Challenge 4",
    link: "https://www.youtube.com/embed/E5oQuCwTrSs",
    description:
      "In today's episode we are continuing our Compound Effect Challenge Series with Challenge 4. Let's keep the Conpound Effect going long after this week ends. Its power is in consistency over the long term. Share your efforts, stories and wins, we are the best in our business! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "94",
    title: "The Compound Effect Challenge Series, Challenge 5",
    link: "https://www.youtube.com/embed/Ba-aGmOfy8c",
    description:
      "In today's episode we are finishing up our Compound Effect Challenge Series with our 5th Challenge. This Challenge is not finished though, it's never finished. The compound effect effort will eventually turn into a habit and way of life. It will aid in your success for the rest of your career. It will benefit you day in and day out. Keep this momentum going, share your success, challenges and stories, let's keep this going! You are the best! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "95",
    title: 'Mastering "You" & Your Routine',
    link: "https://www.youtube.com/embed/lqRHdNEMJg8",
    description:
      'In today\'s episode we are are kicking off a new series titled Mastering "YOU", and we are starting with your daily routines. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "96",
    title: 'Mastering "YOU" and Your Daily Routine',
    link: "https://www.youtube.com/embed/m13d9Xzxgjs",
    description:
      'In today\'s episode we are continuing our Mastering "YOU" series and tackling our daily routines. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "97",
    title: 'Mastering "YOU" and Your Mental Health',
    link: "https://www.youtube.com/embed/LhxNPWfQUIg",
    description:
      'In today\'s episode we are continuing our Mastering "YOU" Series with mastering our Mental Health. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "98",
    title: 'Mastering "YOU" and your Physical Health',
    link: "https://www.youtube.com/embed/h6I5LE5UuvI",
    description:
      'In today\'s episode we are continuing our Mastering "YOU" Series with our Physical Health. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "99",
    title: 'Mastering "YOU", Mastering YOU',
    link: "https://www.youtube.com/embed/cOz2KMdzRwQ",
    description:
      'In today\'s episode we are are finishingnup our Mastering "YOU" Series with the most important topic, YOU. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "100",
    title: "Celebrating 100 Episodes !!",
    link: "https://www.youtube.com/embed/PZJIGni1piw",
    description:
      "In today's episode we are are celebrating our 100th Episode. Thank you so much for tuning in these last 4 months! We are looking forward to 100+ more. Thank you soooooo much!!🏘🏘🏠🏠🏡🏡😎Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: 101,
    title: " Maximizing Your Clients Equity",
    link: "https://www.youtube.com/embed/_QyOekLtT9Y",
    description:
      "In today's episode we are maximizing our clients equity. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: 102,
    title: "Read Your Contracts!",
    link: "https://www.youtube.com/embed/NHbuofTPDNY",
    description:
      "In today's episode we are are sharing a crazy story illustrating why you MUST read tour contracts. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: 103,
    title: 'Auction Homes Can Be "Fun"',
    link: "https://www.youtube.com/embed/0ykNYs4614A",
    description:
      "In today's episode we are talking about why Open Houses (or any number of events, or news) PLUS Compounded Efforts EQUALS Massive Sales. More is Absolutely More in this case. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: 104,
    title: "Open Houses + Coumpounded Efforts = Massive Sales",
    link: "https://www.youtube.com/embed/Vqt_Ki0q0yM",
    description:
      "This week we are focusing on follow up. Starting off with todays episode on internet leads. This is where most agents are getting their leads today, so I figured let's start here. Of course speed to lead is where it begins, but where do we go from there and how. All in 3 minutes, boom! <br/>Daily tidbits are designed and meant to help you get up and running on the best note each day by offering motivational, entertaining and educational content every morning. If you like these episodes, find them helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: 105,
    title: "Target Your Marketing",
    link: "https://www.youtube.com/embed/8pQDf-iLQOA",
    description:
      "In today's episode we are are sharing the huge benefits of Targeting your Marketing. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "106",
    title: "Its About the Process, Not the Destination",
    link: "https://www.youtube.com/embed/j6XCkm796v8",
    description:
      "In today's episode we are are reminding ourselves to take a step back and enjoy the process and journey on the way to the destination. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "107",
    title: "Fail, Fail Again, Fail Better",
    link: "https://www.youtube.com/embed/ZbSJqvtwGVw",
    description:
      "In today's episode we are learning to Fail, Fail Again, and Fail Better. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "108",
    title: "MINDSET Is Everything",
    link: "https://www.youtube.com/embed/msuoDIw8eEA",
    description:
      "In today's episode we are going over an AMAZING book called Mindset by Carol S. Dweck. Mindset is everything! Do you have a growth or fixed mindset? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "109",
    title: "Have a THRIVING business in a Transitioning Market",
    link: "https://www.youtube.com/embed/hZmLaDUSf0c",
    description:
      "In today's episode we are sharing 3 keys to having a thriving business in a transitioning market. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "110",
    title: "Let's get a few more listings THIS WEEK.",
    link: "https://www.youtube.com/embed/bJzC69mvm5w",
    description:
      "In today's episode we are going to make a plan to get a few more listings this week. Ready? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "111",
    title: "The CRAZY reality of interest rates",
    link: "https://www.youtube.com/embed/DiFABij2d04",
    description:
      "In today's episode we are learning the crazy reality of interest rates. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "112",
    title: "Top 10 Tax Benefits of Rental Property",
    link: "https://www.youtube.com/embed/4sGf02YAou4",
    description:
      "In today's episode we are going over the Top 10 Tax benefits of owning a rental property. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "113",
    title: "More Benefits of Owning Rental Property",
    link: "https://www.youtube.com/embed/Vx3l_JS_LQE",
    description:
      "In today's episode we are going over more of the MANY benefits of having rental property. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "114",
    title: "Patience is a Virtue",
    link: "https://www.youtube.com/embed/nix-TdwUjv8",
    description:
      "In today's episode we are reiterating why Patience IS a virtue. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "115",
    title: "When is the Best Time to Plant a Tree?",
    link: "https://www.youtube.com/embed/40cEO0LPsGk",
    description:
      'In today\'s episode we are talking, "When is the best time to Plant a Tree?". Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "116",
    title: "Rent VS. Buy",
    link: "https://www.youtube.com/embed/14Nns-4E5CU",
    description:
      "In today's episode we are discussing if it's better to rent or to buy a home. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "117",
    title: "Finding the Truth in the Media",
    link: "https://www.youtube.com/embed/ui7ANaZ2-2k",
    description:
      "In today's episode we are finding the truth in the news and media we are inundated with daily. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "118",
    title: "How Many Calls Can You Make In A Day?",
    link: "https://www.youtube.com/embed/aTwqWHUPm50",
    description:
      "In today's episode we are challenging ourselves to find out how many calls we can make in a day. Let's rock this! \n Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "119",
    title: "How Many Doors Can You Knock Tomorrow?",
    link: "https://www.youtube.com/embed/-xoJZmuKLNo",
    description:
      "In today's episode we are are challenging and prepping to knock on as many doors as possible tomorrow. Rockin! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "120",
    title: "How Many Open Houses Can You Hold Next Week?",
    link: "https://www.youtube.com/embed/sK8xqyyMrh4",
    description:
      "In today's episode we are challenging ourselves to see how many open house you can hold next week. Your volume and sales are on the edge of exploding! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "121",
    title: "The New Battle In Real Estate Pt 1",
    link: "https://www.youtube.com/embed/OWz2hH1DtoI",
    description:
      "In today's episode we are discussing the New Battle In Real Estate. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "122",
    title: "The New Battle In Real Estate, Pt 2",
    link: "https://www.youtube.com/embed/r_0WdsVP9Us",
    description:
      "In today's episode we are continuing our discussion about the New Battle In Real Estate. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "123",
    title: "The New Battle In Real Estate, Pt 3",
    link: "https://www.youtube.com/embed/dIGvHhp30ag",
    description:
      "In today's episode we are continuing our discussion about the New Battle In Real Estate. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "124",
    title: "The New Battle In Real Estate, Pt 4",
    link: "https://www.youtube.com/embed/t1ej7jNfU-c",
    description:
      "In today's episode we are continuing our discussion about the New Battle In Real Estate. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "125",
    title: "The New Battle In Real Estate, Pt 5",
    link: "https://www.youtube.com/embed/j9EI7mxq-y0",
    description:
      "In today's episode we are continuing our discussion about the New Battle In Real Estate. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "126",
    title: "The New Battle in Real Estate, Pt. 6",
    link: "https://www.youtube.com/embed/rhqzeGeY7-c",
    description:
      "In today's episode we are continuing our discussion about the New Battle In Real Estate. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "127",
    title: "The New Battle in Real Estate, Pt 7",
    link: "https://www.youtube.com/embed/bkoUtJ6EgIE",
    description:
      "In today's episode we are finishing our discussion about the New Battle In Real Estate. This is not just a video, it's a call to Action for everyone in the Real Estate Industry.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "128",
    title: "The One Thing Day 16: Investors",
    link: "https://www.youtube.com/embed/kP_1m_9-MV4",
    description:
      "In today's episode we are Focusing on our one thing which is rocking as many investors as possible. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "129",
    title: "The One Thing Day 17: A Generic Ad",
    link: "https://www.youtube.com/embed/Bv70I3duLn4",
    description:
      "In today's episode we are Focusing on our one thing which is having and using a generic ad. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "130",
    title: "The One Thing Day 18: MLS",
    link: "https://www.youtube.com/embed/vY4zlTSiq6s",
    description:
      "In today's episode we are Focusing on our one thing which is diving into the MLS. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "131",
    title: "The One Thing Day 19: Market STATS",
    link: "https://www.youtube.com/embed/BWPZh3ooLhc",
    description:
      "In today's episode we are Focusing on our one thing which is having and USING market knowledge. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "132",
    title: "The One Thing Day 20: Portal & Auto Updates Happy Labor Day!",
    link: "https://www.youtube.com/embed/XKEJyeuNlVc",
    description:
      "In today's episode we are Focusing on our one thing which is setting up and using portals and Auto updates. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "133",
    title: "The One Thing Day 21: Follow Up Plans",
    link: "https://www.youtube.com/embed/SWSbtAMG_wU",
    description:
      "In today's episode we are continuing to focus on our one thing each day, today's one thing is Follow Up Plans. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "134",
    title: "The One Thing Day 22: Another Generic Ad",
    link: "https://www.youtube.com/embed/COjrX-xmMP8",
    description:
      "In today's episode we are Focusing on our one thing which is making another Generic Ad. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "135",
    title: "The One Thing Day 23: Slogans and Taglines",
    link: "https://www.youtube.com/embed/wO6fXGZKgDw",
    description:
      "In today's episode we are Focusing on our one thing which is having a Slogan and tagline. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "136",
    title: "The One Thing Day 24: Making A Video",
    link: "https://www.youtube.com/embed/-Ngi5AwsxEY",
    description:
      "In today's episode we are Focusing on our one thing which is making a video. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "137",
    title: "The One Thing Day 25: Your Value Proposition",
    link: "https://www.youtube.com/embed/8DmkAtl4qeE",
    description:
      "In today's episode we are Focusing on our one thing which is having a great value proposition. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "138",
    title: "The One Thing Day 26: Send Value To Your Buyers",
    link: "https://www.youtube.com/embed/hiPlCC3Z2Gw",
    description:
      "In today's episode we are Focusing on our one thing which is sending value to your buyer's. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "139",
    title: "The One Thing Day 27: Send Value To Sellers",
    link: "https://www.youtube.com/embed/GD2LVJvQmIE",
    description:
      "In today's episode we are Focusing on our one thing which is sending value to your sellers Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "140",
    title: "The One Thing Day 28: Referrals",
    link: "https://www.youtube.com/embed/05myU9AtYkM",
    description:
      "In today's episode we are Focusing on our one thing which is asking for referrals. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "141",
    title: "The One Thing Day 29: Videos",
    link: "https://www.youtube.com/embed/6nJnF7a_zAs",
    description:
      "In today's episode we are Focusing on our one thing which is making another video. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "142",
    title: "The One Thing Day 41: Buyer Leads",
    link: "https://www.youtube.com/embed/_9KV-ZHKLAE",
    description:
      "In today's episode we are focusing on our One Thing: Buyer Leads Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "143",
    title: "The One Thing Day 42: Top Yesterday!",
    link: "https://www.youtube.com/embed/uMOxxJ8Jpns",
    description:
      "In today's episode we are focusing on our One Thing: To Top Yesterday Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "144",
    title: "The One Thing Day 43: Pop By's",
    link: "https://www.youtube.com/embed/QZ-KofetDOY",
    description:
      "In today's episode we are focusing on our One Thing: Pop By's Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "145",
    title: "The One Thing Day 44: Past Clients",
    link: "https://www.youtube.com/embed/F3-Q8-dlmXk",
    description:
      "In today's episode we are focusing on our One Thing: Past Clients, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "146",
    title: "The One Thing Day 45: Practice Your Listing Presentation",
    link: "https://www.youtube.com/embed/9fwn3gbFLjI",
    description:
      "In today's episode we are focusing on our One Thing: Practice Your Listing Presentation, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "147",
    title: "The One Thing Day 46: COMPS",
    link: "https://www.youtube.com/embed/ZjMUX1mHeGI",
    description:
      "In today's episode we are focusing on our One Thing: COMPS. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "148",
    title: "The One Thing Day 47: Becoming A Better Negotiator",
    link: "https://www.youtube.com/embed/JRQkoXcpqYk",
    description:
      "In today's episode we are focusing on our One Thing: Becoming A Better Negotiator. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "149",
    title: "The One Thing Day 48: Past Clients Happy Columbus Day!",
    link: "https://www.youtube.com/embed/I7Oe7aKphso",
    description:
      "In today's episode we are focusing on our One Thing: Past Clients. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "150",
    title: "The One Thing Day 49: CMA's To P.C's",
    link: "https://www.youtube.com/embed/iQFY2z_Vxj8",
    description:
      "In today's episode we are focusing on our One Thing: Open Houses. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },

  {
    id: "151",
    title: "The One Thing Day 50: Open Houses",
    link: "https://www.youtube.com/embed/jy10l8F7mno",
    description:
      "In today's episode we are continuing our objection handler series with the 'List Price' Objection.",
  },
  {
    id: "152",
    title: "The One Thing Day 51: Are You A Thermometer or A Thermostat",
    link: "https://www.youtube.com/embed/nTkpI9bznGA",
    description:
      "In today's episode we are focusing on our One Thing: Are You a Thermometer or a Thermostat, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "153",
    title: "The One Thing Day 52: Top Line Revenue Growth",
    link: "https://www.youtube.com/embed/OE-DknLd95A",
    description:
      "In today's episode we are focusing on our One Thing: Top Line Revenue Growth Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "154",
    title: "Running With Raven",
    link: "https://www.youtube.com/embed/VVifWo5nbTE",
    description:
      "In today's episode we are talking about Running With Raven. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "155",
    title: "Start Rocking A Farm",
    link: "https://www.youtube.com/embed/rEJ6v1dfJFM",
    description:
      "In today's episode we are talking about how you can start rocking a farm. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "156",
    title: "Making A Great Ad",
    link: "https://www.youtube.com/embed/sRjwBG-T5l4",
    description:
      "In today's episode we are talking about how you can make a great ad. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "157",
    title: "Make A Lot of Phone Calls",
    link: "https://www.youtube.com/embed/34vzf4nORBU",
    description:
      "In today's episode we are talking about making phone calls. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "158",
    title: "It's the 4th Quarter! Yay or Oh No?",
    link: "https://www.youtube.com/embed/_mC6CCm4eaA",
    description:
      "In today's episode we are focusing on the 4th quarter! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "159",
    title: "It's You vs. You",
    link: "https://www.youtube.com/embed/C8E3iKfyVpo",
    description:
      "In today's episode we are are discussing the benefits of having and maintaining relationships.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "160",
    title: "Perfecting Your Buyer Presentation",
    link: "https://www.youtube.com/embed/zyjwtq6QVsE",
    description:
      "In today's episode we are talking about how it's you vs. you. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "161",
    title: "You Can't Cost Cut Your Way To Success",
    link: "https://www.youtube.com/embed/OE-DknLd95A",
    description:
      "In today's episode we are focusing on not cost cutting your way to success. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "162",
    title: "Time For Another Open House",
    link: "https://www.youtube.com/embed/ysfCaBq1GhU",
    description:
      "In today's episode we are talking about more open houses!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "163",
    title: "Organizing Your Message",
    link: "https://www.youtube.com/embed/2loRPsA2QWE",
    description:
      "In today's episode we are talking about organizing your calling schedule. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "164",
    title: "How Many Calls Does It Take To Convert Internet Leads?",
    link: "https://www.youtube.com/embed/fbkbinMr53Q",
    description:
      "In today's episode we are continuing Numbers Are The Key To Success (NATKTS) Week with Day 2: Conversions Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "165",
    title: "A Neat Repeat Client Story",
    link: "https://www.youtube.com/embed/oiDfS3RAC2A",
    description:
      "In today's episode we are telling a neat client story. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "166",
    title: "Optimism On The Phones",
    link: "https://www.youtube.com/embed/ZspjAamzfgQ",
    description:
      "In today's episode we are focusing on optimism on the phones. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "167",
    title: "What's Your Most Important Appointment Each Day?",
    link: "https://www.youtube.com/embed/tk0ls4DSNfc",
    description:
      "In today's episode we are asking, what is your most important appointment each day? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "168",
    title: "My Favorite Open House",
    link: "https://www.youtube.com/embed/S_fRs5QPwVI",
    description:
      "In today's episode we are talking about my favorite open house!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "169",
    title: "Planning Ahead For The Holidays",
    link: "https://www.youtube.com/embed/ewYy9EiQ_AU",
    description:
      "In today's episode we are planning ahead for the holidays. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "170",
    title: "Have You Found Your Tribe?",
    link: "https://www.youtube.com/embed/13j2ZNnt0D8",
    description:
      "In today's episode we are talking about finding your tribe. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },

  {
    id: "171",
    title: "Action or Excuses?",
    link: "https://www.youtube.com/embed/sXAG_H8WKMA",
    description:
      "In today's episode we are talking about action or excuses. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "172",
    title: "Offer Value First",
    link: "https://www.youtube.com/embed/wcG5XrNG-UQ",
    description:
      "In today's episode we are talking about offer value first. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "173",
    title: "Never Lose A Deal To A Small Amount Again",
    link: "https://www.youtube.com/embed/LwKtCV__IYw",
    description:
      "In today's episode we are talking about how to not lose a deal to a small amount again. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "174",
    title: "Get A Few Listings Today",
    link: "https://www.youtube.com/embed/prigH2J8yRA",
    description:
      "In today's episode we are talking about getting a few listings today. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "175",
    title: "When Was Your Last REAL Day Off?",
    link: "https://www.youtube.com/embed/OZOlYgIVz-k",
    description:
      "In today's episode we are asking, when was your last REAL day off? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "176",
    title: "How Do We Make A Budget?",
    link: "https://www.youtube.com/embed/2DEnYo4oHsc",
    description:
      "In today's episode we are talking about making a budget. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "177",
    title: "We Are Door Knocking",
    link: "https://www.youtube.com/embed/WTpxd5mvka0",
    description:
      "In today's episode we are doorknocking!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "178",
    title: "Your Intent VS Your Actions",
    link: "https://www.youtube.com/embed/Spe5MiZpeEw",
    description:
      "In today's episode we are focusing on your intent VS your actions, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "179",
    title: "A Scalable and Repeatable Process for Generating New Clients",
    link: "https://www.youtube.com/embed/vXGA3cJFJCk",
    description:
      "In today's episode we are focusing on our scalable and Repeatable Process for Generating New Clients. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "180",
    title: "Epic 2020 Business Plan Pt. 1",
    link: "https://www.youtube.com/embed/bXElpFqilII",
    description:
      "In today's episode we are focusing on our EPIC business plan for 2020!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "181",
    title: "Epic 2020 Business Plan Pt. 2",
    link: "https://www.youtube.com/embed/7TDRZKkFdBw",
    description:
      "In today's episode we are focusing on our EPIC 2020 business plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },

  {
    id: "182",
    title: "Epic 2020 Business Plan Pt. 3",
    link: "https://www.youtube.com/embed/2zpfLBqPaAk",
    description:
      "In today's episode we are going over our EPIC 2020 business plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "183",
    title: "Epic 2020 Business Plan Pt. 4",
    link: "https://www.youtube.com/embed/ozNmX6MQjNA",
    description:
      "In today's episode we are talking about our EPIC 2020 business plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "184",
    title: "Epic 2020 Business Plan Pt. 5",
    link: "https://www.youtube.com/embed/ikRktEOG6Uo",
    description:
      "Welcome to Breakthrough Real Estate Daily Tidbits!!, In today's episode we are focusing on your Epic 2020 Business Plan, finishing it up today. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "185",
    title: "Happy Thanksgiving!",
    link: "https://www.youtube.com/embed/EK4iE97p6h4",
    description:
      "In today's episode we are wishing you an Happy and Safe Thanksgiving!!,Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "186",
    title: 'Talking About Past Clients',
    link: "https://www.youtube.com/embed/qIw4f08KhpM",
    description:
      "In today's episode we are focusing on our Past Clients, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "187",
    title: 'Brand Awareness',
    link: "https://www.youtube.com/embed/StEaL4F6oJk",
    description:
      "In today's episode we are focusing on our Brand Awareness. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "188",
    title: 'Back To The Basics',
    link: "https://www.youtube.com/embed/OaxL0rNA5BM",
    description:
      'In today\'s episode we are going back to the basics. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "189",
    title: 'What Other Services Can You Provide?',
    link: "https://www.youtube.com/embed/u_5JLeJUEHE",
    description:
      'In today\'s episode we are focusing on what other services we can provide? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "190",
    title: 'Standards',
    link: "https://www.youtube.com/embed/dWROTRi5e6w",
    description:
      'In today\'s episode we are focusing on our Standards, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "191",
    title: "Helping Others Makes Us Happy",
    link: "https://www.youtube.com/embed/3UJuX17f73o",
    description:
      "In today's episode we are focusing on others... Because it also makes us happy. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "192",
    title: "Stay In Front Of People",
    link: "https://www.youtube.com/embed/epajaAtWZmI",
    description:
      "In today's episode we are focusing on Staying In Front Of People, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "193",
    title: "Thank You",
    link: "https://www.youtube.com/embed/Jy96N0DUTvE",
    description:
      'In today\'s episode we are talking about "Thank You\'s",Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!'
  },
  {
    id: "194",
    title: "Focus On The People",
    link: "https://www.youtube.com/embed/zqoDGUYxjuU",
    description:
      "In today's episode we are focusing on the people. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "195",
    title: "What Makes You Unique?",
    link: "https://www.youtube.com/embed/ND0-RH3T6PI",
    description:
      "In today's episode we are talking about what makes you unique, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "196",
    title: 'What Are Your Core Values?',
    link: "https://www.youtube.com/embed/CF_gJEL7LIw",
    description:
      "In today's episode we are asking, what are your core values? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "197",
    title: 'Why Do So Many Real Estate Professionals Fail?',
    link: "https://www.youtube.com/embed/QKf1CpLrVWE",
    description:
      "In today's episode we are talking about why so many real estate professionals fail. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "198",
    title: 'What Do You Look For In A Brokerage?',
    link: "https://www.youtube.com/embed/DkGqCijaM6k",
    description:
      "In today's episode we are asking, what do you look for in a brokerage? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "199",
    title: 'Merry Christmas',
    link: "https://www.youtube.com/embed/qzoP7jgfU-M",
    description:
      "In today's episode we are wishing you a Merry Christmas!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "200",
    title: "Which Is Better, Team or Individual Agent?",
    link: "https://www.youtube.com/embed/nFPPjQUqmdU",
    description:
      "Welcome to Breakthrough Real Estate Daily Tidbits!!, In today's episode we are asking, which is better, team or individual agent? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "201",
    title: "The 2020 Survey",
    link: "https://www.youtube.com/embed/OxJ1KYXOJd4",
    description:
      "In today's episode we are talking about the 2020 survey. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "202",
    title: "Let's Plant a Tree",
    link: "https://www.youtube.com/embed/EGZMhflLLAU",
    description:
      "In today's episode we are talking about planting a tree. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "203",
    title: "Happy New Years Eve",
    link: "https://www.youtube.com/embed/-uGh-xu5o50",
    description:
      "In today's episode we are wishing you a wonderful, happy, safe, fun and amazing New Year's Eve!!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "204",
    title: "Happy New Year!",
    link: "https://www.youtube.com/embed/SgLQ41LlPZE",
    description:
      "Happy New Year!!! 2020, may it be your best year yet!!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "205",
    title: "What Do You Do When You Are Down",
    link: "https://www.youtube.com/embed/PNNkUCQfyu4",
    description:
      "In today's episode we are talking about what you do when you are down. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "206",
    title: "Adapt, Don't Jump Ships",
    link: "https://www.youtube.com/embed/YQe1W5cvXJY",
    description:
      "In today's episode we are talking about why you should adapt, not jump ship. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "207",
    title: "Make A Game Of It",
    link: "https://www.youtube.com/embed/WhV-r8qogVU",
    description:
      "In today's episode we are talking about making a game of it. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "208",
    title: "It's Jan. 2nd, Let's Get Back To Work",
    link: "https://www.youtube.com/embed/a5uZ69N-bcQ",
    description:
      "In today's episode we are focusing on our One Thing: Follow Up, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "209",
    title: "The HAKA",
    link: "https://www.youtube.com/embed/jJznaSTOknY",
    description:
      "Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "210",
    title: "Don't Reinvent The Wheel",
    link: "https://www.youtube.com/embed/D6e0uv0X7yQ",
    description:
      "In today's episode we are talking about not reinventing the wheel. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "211",
    title: "Will A Recession Affect The Real Estate Market",
    link: "https://www.youtube.com/embed/HTWluAgf4z8",
    description:
      "In today's episode we are asking, will a recession affect the real estate market? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "212",
    title: "What Does Being An Outlier In The Real Estate Industry Really Mean",
    link: "https://www.youtube.com/embed/c10T494kKAM",
    description:
      "In today's episode we are talking about what being an outlier in the real estate industry really means. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "213",
    title: "3 Lessons Learned From Thomas Edison Fails",
    link: "https://www.youtube.com/embed/qJCEfixiY2c",
    description:
      "In today's episode we are talking about 3 lessosn learned from Thomas Edison's fails. Learn more at: https://www.smithsonianmag.com/innovation/7-epic-fails-brought-to-you-by-the-genius-mind-of-thomas-edison-180947786/ Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "214",
    title: "Jocko Willink, Extreme Ownership",
    link: "https://www.youtube.com/embed/IlTQM9S1uac",
    description:
      "Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "215",
    title: "EVERY Market Has Its Issues",
    link: "https://www.youtube.com/embed/W0JhUi20NOs",
    description:
      "In today's episode we are talking about every market has its issues. Read More: https://www.forbes.com/sites/johnwake/2018/11/02/the-next-housing-bust/ ,Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "216",
    title: "A Story of Perseverance",
    link: "https://www.youtube.com/embed/g7ANCIBQolg",
    description:
      "In today's episode we are talking about a story of perseverance. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "217",
    title: "Happy Martin Luther King Jr. Day!",
    link: "https://www.youtube.com/embed/xmPGDKLgXfY",
    description: "In today's episode we are wishing you a Happy Martin Luther King Jr. Day!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "218",
    title: "Are You Serious?",
    link: "https://www.youtube.com/embed/fj2k5UTIMRc",
    description: "In today's episode we are asking, are you serious? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "219",
    title: "Imagine This",
    link: "https://www.youtube.com/embed/1bcvVWAWPl8",
    description: "In today's episode we are imagining working 20 hours a week and making $200,000 a year. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "220",
    title: "What Will You Sacrifice To Be Successful?",
    link: "https://www.youtube.com/embed/N2qRVfhi9SE",
    description: "In today's episode we are asking, what will you sacrifice to be successful? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "221",
    title: "Perspective Is Everything",
    link: "https://www.youtube.com/embed/iui85QEFfF4",
    description: "In today's episode we are talking about how perspective is everything. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "222",
    title: "Thoughts on the Zillow & Trulia Lawsuit",
    link: "https://www.youtube.com/embed/gCNU_TisBV4",
    description: "In today's episode we are talking about the Zillow & Trulia Lawsuit. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "223",
    title: "Happiness IS A Choice",
    link: "https://www.youtube.com/embed/cFqzU5A71FI",
    description: "In today's episode we are talking about how happiness is a choice. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "224",
    title: "Your Cracks Are How Your Light Shines Out",
    link: "https://www.youtube.com/embed/DW-zqHZlX7g",
    description: "In today's episode we are talking about how your cracks are how your light shines out. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "225",
    title: "No More Excuses",
    link: "https://www.youtube.com/embed/QcMpKvhtvJQ",
    description: "In today's episode we are talking about no more excuses. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "226",
    title: "Why Is Regularity So Hard Pt. 1",
    link: "https://www.youtube.com/embed/YVYvSRfiO0g",
    description: "In today's episode we are asking, why is regularity so hard? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "227",
    title: "Why Is Regularity So Hard, 10 Steps To Mastering This Skill Pt. 2",
    link: "https://www.youtube.com/embed/YxpzJsf4NFI",
    description: "In today's episode we are asking, why is regularity so hard and 10 tips to mastering this skill. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "229",
    title: "5 Steps to Overcome Call Reluctance",
    link: "https://www.youtube.com/embed/tKNe1jjEU2s",
    description: "In today's episode we are going over 5 tips to overcome call reluctance. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "229",
    title: "To Open House, Or Not To Open House, That Is The Question",
    link: "https://www.youtube.com/embed/xM0IJjTcwK0",
    description: "In today's episode we are asking, to open house or not to open house, that is the question. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "230",
    title: "Optimism IS Better For Your Health",
    link: "https://www.youtube.com/embed/xeCQWC2WHik",
    description: "In today's episode we are talking about why optimism is better for your health.Learn more about this topic: https://www.psychologytoday.com/blog/the-science-behind-behavior/201607/4-reasons-why-optimistic-outlook-is-good-your-health?eml , Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "231",
    title: "8 Tips To Stick To A Plan & Accomplish Your Goals",
    link: "https://www.youtube.com/embed/VIKLXrDWhfs",
    description: "In today's episode we are going over 8 tips to stick to a plan & accomplish your goals. https://www.inc.com/wanda-thibodeaux/8-tips-to-help-you-stick-to-your-game-plan-finally-reach-your-goals.html, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "232",
    title: "Building Rapport Is Everything",
    link: "https://www.youtube.com/embed/s7KVHuTtu28",
    description: "In today's episode we are going over why building rapport is everything. https://www.callcentrehelper.com/27-effective-ways-to-build-customer-rapport-42018.html, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "233",
    title: "More Tips and Tricks To Build Rapport",
    link: "https://www.youtube.com/embed/dF6emeLpNQU",
    description: "In today's episode we are going over more tips and tricks to build rapport.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "234",
    title: "More Tips and Tricks To Build Rapport",
    link: "https://www.youtube.com/embed/p69PXP3XYDE",
    description: "In today's episode we are going over more tips and tricks to build rapport.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "235",
    title: "More Tips and Tricks To Build Rapport, We're Finishing The List",
    link: "https://www.youtube.com/embed/0aoFmWVp9TM",
    description: "In today's episode we are going over more tips and tricks to build rapport. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "236",
    title: "God Placed The Best Things In Life On The Other Side Of Fear",
    link: "https://www.youtube.com/embed/Egf0PduOJ_0",
    description: "In today's episode we are talking about how God placed the best things in life on the other side of fear. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "237",
    title: "You Are More Powerful Than You Think, Goggins 40% Rule",
    link: "https://www.youtube.com/embed/Q-43Q8bkYO8",
    description: " In today's episode we are talking about Goggins 40% Rule. https://www.youtube.com/embed/PaLC_4aBoU0 Check out these books about David goggins: • Can't Hurt Me: Master Your Mind and Defy the Odds : https://amzn.to/2s6fAPd • Living with a SEAL: 31 Days Training with the Toughest Man on the Planet : https://amzn.to/2Rwmvzk FOLLOW DAVID GOGGINS: Website: http://davidgoggins.com Facebook: https://www.facebook.com/iamdavidgogg... Instagram: instagram.com/iamdavidgoggins Twitter: twitter.com/iamdavidgoggins Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "238",
    title: "You Have Far More In You Than You Think",
    link: "https://www.youtube.com/embed/1OazbDEMp_s",
    description: "In today's episode we are talking about how you have far more in you than you think. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "239",
    title: "You Have To Know Your Destination",
    link: "https://www.youtube.com/embed/iTgBmXLcDIA",
    description: "In today's episode we are talking about knowing your destination. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "240",
    title: "Time For Another Marathon Calling Session",
    link: "https://www.youtube.com/embed/LNipbF__TtM",
    description: "In today's episode we are talking about how it's time for another marathon calling session. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "241",
    title: "Work On Your Business, NOT In Your Business",
    link: "https://www.youtube.com/embed/n9rNB9mWXVY",
    description: "In today's episode we are working on your business, NOT in your business. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "242",
    title: "Real Estate Professionals Retirement. Do You Have One?",
    link: "https://www.youtube.com/embed/Aw82J5a2Qhw",
    description: "In today's episode we are talking about your retirement. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "243",
    title: "1 Huge Lesson Learned From Kobe Bryant",
    link: "https://www.youtube.com/embed/3A1jGohW90A",
    description: "In today's episode we are going over 1 HUGE lesson learned from Kobe Bryant. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "244",
    title: "Lessons Learned From Neil Peart",
    link: "https://www.youtube.com/embed/C8P68XiLpkw",
    description: "In today's episode we are talking about lessons learned from Neil Peart. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "245",
    title: "8 Stats All Salespeople Should Know",
    link: "https://www.youtube.com/embed/vfq9jnqQqeY",
    description: "In today's episode we are going over 8 stats that ALL salespeople should know. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "246",
    title: "Key STATS To SELL Better In 2020",
    link: "https://www.youtube.com/embed/iSDF8a_me2U",
    description: "In today's episode we are going over key stats to sell better in 2020. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "247",
    title: "STATS To Make 2020 Your Best Year Yet",
    link: "https://www.youtube.com/embed/5IHH54pSA1w",
    description: "In today's episode we are going over stats to make 2020 your best year yet. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "248",
    title: "More STATS To Make 2020 Your Best Year Yet",
    link: "https://www.youtube.com/embed/hVe8CYVZtRo",
    description: "In today's episode we are going over more stats to make 2020 your best year yet. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "249",
    title: "More STATS To Make 2020 Your Best Year Yet",
    link: "https://www.youtube.com/embed/ciV7EeirEXU",
    description: "In today's episode we are going over more stats to make 2020 your best year yet. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "250",
    title: "4 Tips For Emails That Make Maximum Impact",
    link: "https://www.youtube.com/embed/knBsGNizPsE",
    description: "In today's episode we are going over 4 tips for emails that make maximum impact. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "251",
    title: "5 Tricks To Take Your Email Response Rate Through The Roof",
    link: "https://www.youtube.com/embed/v_zY_9uUhMU",
    description: "In today's episode we are going over 5 tricks to take your email response rate through the roof. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "252",
    title: "6 Ways To Have Your Calls Make Maximum Impact",
    link: "https://www.youtube.com/embed/GELrKr2MPoU",
    description:
      "In today's episode we are going over 6 ways to have your calls make the maximum impact. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "253",
    title: "6 Tips To Becoming A Calling Master",
    link: "https://www.youtube.com/embed/GlrsL8OUu1w",
    description:
      "In today's episode we are going over 6 steps to become a calling master. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "254",
    title: "How Important Is Social Media In Marketing Today?",
    link: "https://www.youtube.com/embed/hVmcV0kfPSM",
    description:
      "In today's episode we are asking how important social media in marketing today? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "255",
    title: "Want To Be More Productive",
    link: "https://www.youtube.com/embed/zQI2le6iXus",
    description:
      "In today's episode we are talking about wanting to be more productive. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "256",
    title: "How Important Are Training and Continuing Education",
    link: "https://www.youtube.com/embed/NkS8Ylgz6Wg",
    description:
      "In today's episode we are asking how important are training and continuing education? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "257",
    title: "How Do You Create A Positive Selling Experience For Your Clients",
    link: "https://www.youtube.com/embed/Mow7ptt9RL4",
    description:
      "In today's episode we are asking how do you create a positive selling experience? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "258",
    title: "How To Create A Positive Selling Experience For Your Clients Pt.2",
    link: "https://www.youtube.com/embed/sLDG3Cw0bUo",
    description:
      "In today's episode we are talking about creating a positive selling experience for your clients. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "259",
    title: "What Are A Salesperson's Top 3 Priorities",
    link: "https://www.youtube.com/embed/ksPOkAO7mVg",
    description:
      "In today's episode we are asking what are a salesperson's top 3 priorities? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "260",
    title: "How Important Is Timing When Getting A New Client?",
    link: "https://www.youtube.com/embed/XtgUJsuD1LU",
    description:
      "In today's episode we are asking how important is timing when getting a new client? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "261",
    title: "Want To Exceed Your Goals",
    link: "https://www.youtube.com/embed/EyrJVq3jD8I",
    description:
      "In today's episode we are talking about wanting to exceed your goals. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "262",
    title: "How Important Are Referrals",
    link: "https://www.youtube.com/embed/Koz8omzpXe4",
    description:
      "In today's episode we are talking about how important referrals are. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "263",
    title: "Did You Plan Your Career In Sales?",
    link: "https://www.youtube.com/embed/Hs6EgRgbuU4",
    description:
      "In today's episode we are asking, did you plan your career in sales? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated",
  },
  {
    id: "264",
    title: "What Are The Benefits Of Compounded Efforts?",
    link: "https://www.youtube.com/embed/Vkvrq02TAus",
    description:
      "In today's episode we are asking what are the benefits of compounded efforts? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "265",
    title: "Schedule A Follow Up Every Time",
    link: "https://www.youtube.com/embed/GGZYphePWKI",
    description:
      "In today's episode we are talking about scheduling a follow up every single time. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "266",
    title: "5 Tips To Be The TOP Salesperson In Your Office",
    link: "https://www.youtube.com/embed/3gJA5f561rM",
    description:
      " In today's episode we are going over 5 tips to be the top salesperson in your office. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "267",
    title: "Do You Want To Be The Top Salesperson In Your Office",
    link: "https://www.youtube.com/embed/Fu5ZaKAVpc4",
    description:
      "In today's episode we are asking, do you want to be the top salesperson in your office? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "268",
    title: "Want to Sell 5 Times More Than Your Competitors",
    link: "https://www.youtube.com/embed/xEWH-Ps0eT0",
    description:
      "In today's episode we are talking about wanting to sell 5 times more than your competitors. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "269",
    title: "What's The Most Affordable Way To Get New Clients",
    link: "https://www.youtube.com/embed/cvj3Rpb-OPA",
    description:
      "In today's episode we are asking, what's the most affordable way to get new clients? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "270",
    title: "What Makes A Great Person Great",
    link: "https://www.youtube.com/embed/eHSBFhESRCU",
    description:
      "In today's episode we are asking what makes a great person great? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "271",
    title: "What Makes A Great Person Great, Wisdom",
    link: "https://www.youtube.com/embed/frmwXA7tyzM",
    description:
      "In today's episode we are asking what makes a person great? Wisdom. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "272",
    title: "What Makes A Great Person Great, Responsibility",
    link: "https://www.youtube.com/embed/5l-99c6SG9o",
    description:
      "In today's episode we are asking what makes a person great? Responsibility. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "273",
    title: "What Makes A Great Person Great, HARD WORK",
    link: "https://www.youtube.com/embed/O2kQK2gNT0g",
    description:
      "In today's episode we are asking what makes a person great? Hard Work. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "274",
    title: "What Makes A Great Person Great, Selflessness",
    link: "https://www.youtube.com/embed/vk6niRvB72E",
    description:
      "In today's episode we are asking what makes a person great? Selflessness. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "275",
    title: "We Are Door Knocking",
    link: "https://www.youtube.com/embed/-WuQPUpfiDM",
    description:
      "In today's episode we are door knocking. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "276",
    title: "What Makes A Great Person Great, Selflessness and Love",
    link: "https://www.youtube.com/embed/SQ_yYlQt98w",
    description:
      "In today's episode we are asking what makes a person great? Selflessness and love. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "277",
    title: "There's Always A Great Market Somewhere",
    link: "https://www.youtube.com/embed/v6lLceQ0hHI",
    description:
      "In today's episode we are talking about how there's always a great market somewhere. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "278",
    title: "COVID-19 Update",
    link: "https://www.youtube.com/embed/v9A3EUZOvVU",
    description:
      "In today's episode we are going over a COVID-19 update. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "279",
    title: "COVID-19 Update",
    link: "https://www.youtube.com/embed/I63XTWhWuYg",
    description:
      "In today's episode we are talking about COVID-19!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "280",
    title: "COVID-19 Update, Keeping Our Heads",
    link: "https://www.youtube.com/embed/SdrXpOh3Qqs",
    description:
      "In today's episode we are talking about keeping our heads during this crazy time. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "281",
    title: "If, Rudyard Kipling",
    link: "https://www.youtube.com/embed/KZV6NisoaYY",
    description:
      "In today's episode we are talking about a poem. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "282",
    title: "What Does 0% Interest Really Mean",
    link: "https://www.youtube.com/embed/pfQZGzDf61k",
    description:
      "In today's episode we are talking about what 0% interest really means. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "283",
    title: "Find The Good",
    link: "https://www.youtube.com/embed/QHlN2hgpS_4",
    description:
      "In today's episode we are talking about finding the good. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "284",
    title: "Getting Back To Basics, Plus Technology",
    link: "https://www.youtube.com/embed/DjYxKTGQjt4",
    description:
      "In today's episode we are talking about getting back to basic, plus technology. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "285",
    title: "Time To Embrace Technology, While Getting Back to the Basics",
    link: "https://www.youtube.com/embed/DjYxKTGQjt4",
    description:
      "In today's episode we are talking about embracing technology. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "286",
    title: 'Back To The Basics, Phones',
    link: "https://www.youtube.com/embed/rFO0i5q6LY4",
    description:
      "In today's episode we are talking about getting back to basic, plus phones. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "287",
    title: 'Back To The Basics, Rocking The Phones',
    link: "https://www.youtube.com/embed/BF0JijmrWCI",
    description:
      "In today's episode we are talking about getting back to the basics, rocking the phones. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "288",
    title: 'Back To The Basics, Rocking The Phones Even Harder',
    link: "https://www.youtube.com/embed/ZHBH2g1yLZ4",
    description:
      "In today's episode we are talking about getting back to the basics, rocking the phones even harder,Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "289",
    title: 'Back To The Basics, Rocking The Phones, Call Em Again',
    link: "https://www.youtube.com/embed/h2agzYfLGJU",
    description:
      "In today's episode we are talking about getting back to the basics, rocking the phones, and calling them again. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "290",
    title: 'Back To The Basics, Rocking The Phones, Sellers',
    link: "https://www.youtube.com/embed/i9Yts4TrI0Q",
    description:
      'In today\'s episode we are talking about getting back to the basics, rocking the phones, sellers.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "291",
    title: "Back To The Basics, Rocking The Phones, Renters",
    link: "https://www.youtube.com/embed/adIJ0h55T-A",
    description:
      "In today's episode we are talking about getting back to the basics, rocking the phones, renters. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "292",
    title: "We Run Ads",
    link: "https://www.youtube.com/embed/mQyny0uCxOk",
    description:
      "In today's episode we are talking about running ads. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "293",
    title: "Are You The Trusted Source Of Information",
    link: "https://www.youtube.com/embed/_R-dp5tYnJA",
    description:
      "In today's episode we are asking if you're the trusted source of information. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "294",
    title: "Are You The Trusted Source Of Information Pt. 2",
    link: "https://www.youtube.com/embed/xb-YCOqy8sQ",
    description:
      "In today's episode we are asking if you're the trusted source of information. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "295",
    title: 'What\'s YOUR Market Forecast?',
    link: "https://www.youtube.com/embed/ppzkDXvrV9Q",
    description:
      "In today's episode we are asking, what's your market forecast? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "296",
    title: 'Frequency Matters',
    link: "https://www.youtube.com/embed/OFF-uDsBAUw",
    description:
      " In today's episode we are talking about how frequency matters. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "297",
    title: 'When Did You Last Send A Mail Campaign?',
    link: "https://www.youtube.com/embed/jn15x9ihito",
    description:
      "In today's episode we are asking, when did you last send a mail campaign. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "298",
    title: 'Oh, Why People Need A GREAT Lender',
    link: "https://www.youtube.com/embed/w0jbYi6tIwc",
    description:
      "In today's episode we are talking about why people need a great lender. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "299",
    title: 'Storytime, Oh, Why People Need A GREAT Lender, Pt. 2',
    link: "https://www.youtube.com/embed/Z_KDQpvrc0M",
    description:
      "In today's episode we are talking about why people need a great lender. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "300",
    title: "Stay Up To Date",
    link: "https://www.youtube.com/embed/Isl9vGUNPXw",
    description:
      "In today's episode we are talking about staying up to date. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "301",
    title: "Post COVID, What Are The Next Steps",
    link: "https://www.youtube.com/embed/XXn2ID_6EmE",
    description:
      "In today's episode we are talking about what the next steps are. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "302",
    title: "Change Is Good",
    link: "https://www.youtube.com/embed/XyGt7lsO-ns",
    description:
      "In today's episode we are talking about how change is good. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },

  // Completed Till Episode 350
  {
    id: "303",
    title: 'Hindsight Is 2020, My Doodlebug',
    link: "https://www.youtube.com/embed/I6WOx0prG7c",
    description:
      "In today's episode we are talking about how hindsight is 2020 Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "304",
    title: "Hindsight Is 2020, A New Job Saved My Life",
    link: "https://www.youtube.com/embed/mDSptXzEmog",
    description:
      "In today's episode we are talking about how a new job saved my life. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "305",
    title: "Hindsight Is 2020, Even An Ex Wife Can Be A Good Thing",
    link: "https://www.youtube.com/embed/ObStC4AhFxU",
    description:
      "In today's episode we are talking about how a even an ex wife can be a good thing. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "306",
    title: "Can We Have Foresight Instead Of Hindsight?",
    link: "https://www.youtube.com/embed/XDtO1LE0pKs",
    description:
      "In today's episode we are asking, can we have foresight instead of hindsight? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "307",
    title: "The Perfect 6 Month Plan, Pt. 1",
    link: "https://www.youtube.com/embed/t5hpRoCZHJc",
    description:
      "In today's episode we are talking about the perfect 6 months follow up plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "308",
    title: "The Perfect 6 Month Plan, They Didn't Answer, Now What?",
    link: "https://www.youtube.com/embed/x4qrXzC7Tz4",
    description:
      "In today's episode we are talking about the perfect 6 months follow up plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "309",
    title: "The Perfect 6 Month Plan, The Magical 3rd Follow Up",
    link: "https://www.youtube.com/embed/ijyfHlM9SuU",
    description:
      "In today's episode we are talking about the perfect 6 months follow up plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "310",
    title: "The Perfect 6 Month Plan, Fantastic 5th Follow Up",
    link: "https://www.youtube.com/embed/tHGYCocSFs0",
    description:
      "In today's episode we are talking about the perfect 6 months follow up plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.",
  },
  {
    id: "311",
    title: "The Perfect 6 Month Plan, Follow Up 7",
    link: "https://www.youtube.com/embed/aDn1czitUYM",
    description:
      "In today's episode we are talking about the perfect 6 months follow up plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.",
  },
  {
    id: "312",
    title: "The Perfect 6 Month Plan, Follow Ups 10+",
    link: "https://www.youtube.com/embed/ahVbfXFmP6U",
    description:
      "In today's episode we are talking about the perfect 6 months follow up plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.",
  },
  {
    id: "313",
    title: "The Perfect 6 Month Plan, Follow Ups 15-20",
    link: "https://www.youtube.com/embed/KXGa7-7Jpco",
    description:
      "In today's episode we are talking about the perfect 6 months follow up plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "314",
    title: "Reliability VS Honesty",
    link: "https://www.youtube.com/embed/dmdZkKVCqT8",
    description:
      "In today's episode we are talking about realiability vs. honesty. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "315",
    title: "Daily Dose Of Happy",
    link: "https://www.youtube.com/embed/QEdd7D5mzx8",
    description:
      "In today's episode we are talking about a daily dose of happy Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "316",
    title: "How To Maximize Your Time In The Car",
    link: "https://www.youtube.com/embed/1rVLjhKZOzo",
    description:
      "In today's episode we are talking about maximizing your time in the car. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "317",
    title: "Do The Job Right",
    link: "https://www.youtube.com/embed/x6W043_JuRI",
    description:
      "In today's episode we are talking about doing the job right. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "318",
    title: "COVID-19 Update",
    link: "https://www.youtube.com/embed/1NQ78OdPGdw",
    description:
      "In today's episode we are talking about a COVID-19 update. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "319",
    title: "Gratitude Series 1",
    link: "https://www.youtube.com/embed/j9kgC0f9Tlo",
    description:
      "Today's episode takes a look on the importance of gratitude. I sat down today to begin writing out my list of people I'm grateful for, people who made me who I am today. This is something I think we all do in one way or another from time to time, but it wasn't until I took the time to sit down and write out every person who has made an impact on me that I was hit by the power of gratitude. Community -- the people around us -- make us who we are. I am thankful today to take a look at one small portion of those people in my life. Make sure you join us in the coming weeks as we continue our series on gratitude and how it can shape your life and business for the better.",
  },
  {
    id: "320",
    title: "Who Are You Grateful For?",
    link: "https://www.youtube.com/embed/hvJ1I-tUUp8",
    description:
      "From the owner, Josh: \"Today's episode takes a look on the importance of help. This is actually something I just spoke about with one of our employees the other day -- how so often, people are not just unwilling to ask for help, but they don't even THINK to ask for it. So often we strive to work hard and pull ourselves up by our boot straps that we forget there's only benefits to be gained through teamwork. Today, I take a moment to reflect on two people who have put aside a ton of time to help me out, often without me even having to ask for it. Never forget to show those people -- the ones who seek to help without being asked -- how much you appreciate them.\" Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "321",
    title: "Gratitude Series",
    link: "https://www.youtube.com/embed/AlwJWfSdELU",
    description:
      "Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "322",
    title: "Gratitude for Clients & Customers",
    link: "https://www.youtube.com/embed/5eqXg1e6Icg",
    description:
      "Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "323",
    title: "How To Handle Burnout",
    link: "https://www.youtube.com/embed/nbpKX7QId20",
    description:
      "Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "324",
    title: "No Excuses",
    link: "https://www.youtube.com/embed/DRRSca5pf-I",
    description:
      "Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "325",
    title: "Sticktoitiveness",
    link: "https://www.youtube.com/embed/wEzB8xV4zC8",
    description:
      "Today's daily tidbit focuses on sticktoitiveness and why it's so important. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "326",
    title: "Parkinson Law",
    link: "https://www.youtube.com/embed/l_lG9P-HEzg",
    description:
      "Today's daily tidbit focuses on Parkinson Law. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated",
  },
  {
    id: "327",
    title: "15 Step Guide, Step 1",
    link: "https://www.youtube.com/embed/hD9S-_2a5c0",
    description:
      "Today's daily tidbit focuses on Step 1 of our 15 step guide. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "328",
    title: "15 Step Guide, Step 2",
    link: "https://www.youtube.com/embed/Qnd6FIAQVds",
    description:
      "In today's episode we are Focusing on our one thing which is rocking as many investors as possible. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "329",
    title: "15 Step Guide, Step 3",
    link: "https://www.youtube.com/embed/YsUQZXK7V-A",
    description:
      "Today's daily tidbit focuses on Step 2 of our 15 step guide. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "330",
    title: "Happy Labor Day!",
    link: "https://www.youtube.com/embed/opj85z226_s",
    description:
      "Today, we are wishing you a fun and safe Labor Day! Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "331",
    title: "15 Step Guide, Step 4",
    link: "https://www.youtube.com/embed/YZ9Yxwukduc",
    description:
      "Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!, Our community is everything to us and we want to hear from YOU! Send in YOUR tidbits and stories to tidbits@brepm.com and comment all your tips, crazy experiences, and awesome (or annoying) things that only we Real Estate Pros can appreciate!",
  },
  {
    id: "332",
    title: "15 Step Guide, Step 5",
    link: "https://www.youtube.com/embed/Sj-nYMgoC0I",
    description:
      "Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!Our community is everything to us and we want to hear from YOU! Send in YOUR tidbits and stories to tidbits@brepm.com and comment all your tips, crazy experiences, and awesome (or annoying) things that only we Real Estate Pros can appreciate!",
  },
  {
    id: "333",
    title: "15 Step Guide, Step 6",
    link: "https://www.youtube.com/embed/JxOSGArkdDg",
    description:
      "Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!Our community is everything to us and we want to hear from YOU! Send in YOUR tidbits and stories to tidbits@brepm.com and comment all your tips, crazy experiences, and awesome (or annoying) things that only we Real Estate Pros can appreciate!",
  },
  {
    id: "334",
    title: "15 Step Guide, Step 7",
    link: "https://www.youtube.com/embed/S2Z1jEqbd1o",
    description:
      "Today's daily tidbit focuses on Step 7 of our 15 step guide. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "335",
    title: "15 Step Guide, Step 8",
    link: "https://www.youtube.com/embed/i2p3MQr38J8",
    description:
      "Today's daily tidbit focuses on Step 8 of our 15 step guide. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "336",
    title: "15 Step Guide, Step 9",
    link: "https://www.youtube.com/embed/Y0NLp9VIo_o",
    description:
      "Today's daily tidbit focuses on Step 9 of our 15 step guide. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "337",
    title: "15 Step Guide, Step 10",
    link: "https://www.youtube.com/embed/m7JePvl8_dU",
    description:
      "Today's daily tidbit focuses on Step 10 of our 15 step guide. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "338",
    title: "15 Step Guide, Step 11",
    link: "https://www.youtube.com/embed/tC8FVD_sg8Y",
    description:
      "Today's daily tidbit focuses on Step 11 of our 15 step guide. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "339",
    title: "15 Step Guide, Step 12",
    link: "https://www.youtube.com/embed/07BFVXdWQ9k",
    description:
      "Today's daily tidbit focuses on Step 12 of our 15 step guide. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "340",
    title: "15 Step Guide, Step 13",
    link: "https://www.youtube.com/embed/byI2ZAfe0rI",
    description:
      "Today's daily tidbit focuses on Step 13 of our 15 step guide. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "341",
    title: "15 Step Guide, Step 14",
    link: "https://www.youtube.com/embed/6nJnF7a_zAs",
    description:
      "In today's episode we are Focusing on our one thing which is making another video. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "342",
    title: "15 Step Guide, Step 15",
    link: "https://www.youtube.com/embed/_9KV-ZHKLAE",
    description:
      "In today's episode we are focusing on our One Thing: Buyer Leads Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "343",
    title: "Under Promise, Over Deliver",
    link: "https://www.youtube.com/embed/cV9D4VSEH9c",
    description:
      "Today's daily tidbit focuses on under promising and over delivering. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "344",
    title: "Communication Styles",
    link: "https://www.youtube.com/embed/BfebNHLStXg",
    description:
      "Today's daily tidbit focuses on commuication styles. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "345",
    title: "Communication Styles Pt. 2",
    link: "https://www.youtube.com/embed/1mxRCOAjepo",
    description:
      "Today's daily tidbit focuses on commuication styles. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "346",
    title: "Communication Styles Pt. 3",
    link: "https://www.youtube.com/embed/DHjC6wEZLG8",
    description:
      "Today's daily tidbit focuses on commuication styles. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "347",
    title: "Responsibility",
    link: "https://www.youtube.com/embed/KAK5ln3mmHo",
    description:
      "Today's daily tidbit focuses on responsibility. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "348",
    title: "No Excuses",
    link: "https://www.youtube.com/embed/qo1Gw7GQWu8",
    description:
      "Today's daily tidbit focuses on not giving excuses. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "349",
    title: "Hard Work",
    link: "https://www.youtube.com/embed/AXmXRj5iB5k",
    description:
      "Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "350",
    title: "What Are You Willing To Do?",
    link: "https://www.youtube.com/embed/WSNjVDWjOhA",
    description:
      "Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "351",
    title: "Work Life Balance 1",
    link: "https://www.youtube.com/embed/C_kmrgvsS_s",
    description:
      "In today's episode we are continuing our objection handler series with the 'List Price' Objection.",
  },
  {
    id: "352",
    title: "Work Life System",
    link: "https://www.youtube.com/embed/IAZeQJf5qEs",
    description:
      "Today's daily tidbit talks about a work life system. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "353",
    title: "Systems For Life ",
    link: "https://www.youtube.com/embed/ivU-frowsTE",
    description:
      "Today's daily tidbit talks about systems for life. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "354",
    title: "Systems For Life Pt. 2",
    link: "https://www.youtube.com/embed/RGSKSHlYsoo",
    description:
      "Today's daily tidbit talks about systems for life. Our Daily Tidbits are designed to kickstart your day by delivering motivational, educational, and entertaining content straight to your sub box every day, so make sure to subscribe to our channel and hit the bell to be notified of each morning's Daily Tidbit. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "355",
    title: "Ask The Right Questions Pt. 1",
    link: "https://www.youtube.com/embed/vWdIbtf5DdE",
    description:
      "In today's episode we are talking about how you can start rocking a farm. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  // till 407
  {
    id: "356",
    title: "Making A Great Ad",
    link: "https://www.youtube.com/embed/sRjwBG-T5l4",
    description:
      "In today's episode we are talking about how you can make a great ad. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "357",
    title: "Make A Lot of Phone Calls",
    link: "https://www.youtube.com/embed/34vzf4nORBU",
    description:
      "In today's episode we are talking about making phone calls. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "358",
    title: "It's the 4th Quarter! Yay or Oh No?",
    link: "https://www.youtube.com/embed/_mC6CCm4eaA",
    description:
      "In today's episode we are focusing on the 4th quarter! Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "359",
    title: "It's You vs. You",
    link: "https://www.youtube.com/embed/C8E3iKfyVpo",
    description:
      "In today's episode we are are discussing the benefits of having and maintaining relationships.Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "360",
    title: "Perfecting Your Buyer Presentation",
    link: "https://www.youtube.com/embed/zyjwtq6QVsE",
    description:
      "In today's episode we are talking about how it's you vs. you. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "361",
    title: "You Can't Cost Cut Your Way To Success",
    link: "https://www.youtube.com/embed/OE-DknLd95A",
    description:
      "In today's episode we are focusing on not cost cutting your way to success. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning.If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "362",
    title: "Time For Another Open House",
    link: "https://www.youtube.com/embed/ysfCaBq1GhU",
    description:
      "In today's episode we are talking about more open houses!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "363",
    title: "Organizing Your Message",
    link: "https://www.youtube.com/embed/2loRPsA2QWE",
    description:
      "In today's episode we are talking about organizing your calling schedule. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "364",
    title: "How Many Calls Does It Take To Convert Internet Leads?",
    link: "https://www.youtube.com/embed/fbkbinMr53Q",
    description:
      "In today's episode we are continuing Numbers Are The Key To Success (NATKTS) Week with Day 2: Conversions Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "365",
    title: "A Neat Repeat Client Story",
    link: "https://www.youtube.com/embed/oiDfS3RAC2A",
    description:
      "In today's episode we are telling a neat client story. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "366",
    title: "Optimism On The Phones",
    link: "https://www.youtube.com/embed/ZspjAamzfgQ",
    description:
      "In today's episode we are focusing on optimism on the phones. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "367",
    title: "What's Your Most Important Appointment Each Day?",
    link: "https://www.youtube.com/embed/tk0ls4DSNfc",
    description:
      "In today's episode we are asking, what is your most important appointment each day? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "368",
    title: "My Favorite Open House",
    link: "https://www.youtube.com/embed/S_fRs5QPwVI",
    description:
      "In today's episode we are talking about my favorite open house!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "369",
    title: "Planning Ahead For The Holidays",
    link: "https://www.youtube.com/embed/ewYy9EiQ_AU",
    description:
      "In today's episode we are planning ahead for the holidays. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "370",
    title: "Have You Found Your Tribe?",
    link: "https://www.youtube.com/embed/13j2ZNnt0D8",
    description:
      "In today's episode we are talking about finding your tribe. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },

  {
    id: "371",
    title: "Action or Excuses?",
    link: "https://www.youtube.com/embed/sXAG_H8WKMA",
    description:
      "In today's episode we are talking about action or excuses. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "372",
    title: "Offer Value First",
    link: "https://www.youtube.com/embed/wcG5XrNG-UQ",
    description:
      "In today's episode we are talking about offer value first. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "373",
    title: "Never Lose A Deal To A Small Amount Again",
    link: "https://www.youtube.com/embed/LwKtCV__IYw",
    description:
      "In today's episode we are talking about how to not lose a deal to a small amount again. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "374",
    title: "Get A Few Listings Today",
    link: "https://www.youtube.com/embed/prigH2J8yRA",
    description:
      "In today's episode we are talking about getting a few listings today. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "375",
    title: "When Was Your Last REAL Day Off?",
    link: "https://www.youtube.com/embed/OZOlYgIVz-k",
    description:
      "In today's episode we are asking, when was your last REAL day off? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "376",
    title: "How Do We Make A Budget?",
    link: "https://www.youtube.com/embed/2DEnYo4oHsc",
    description:
      "In today's episode we are talking about making a budget. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "377",
    title: "We Are Door Knocking",
    link: "https://www.youtube.com/embed/WTpxd5mvka0",
    description:
      "In today's episode we are doorknocking!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "378",
    title: "Your Intent VS Your Actions",
    link: "https://www.youtube.com/embed/Spe5MiZpeEw",
    description:
      "In today's episode we are focusing on your intent VS your actions, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "379",
    title: "A Scalable and Repeatable Process for Generating New Clients",
    link: "https://www.youtube.com/embed/vXGA3cJFJCk",
    description:
      "In today's episode we are focusing on our scalable and Repeatable Process for Generating New Clients. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "380",
    title: "Epic 2020 Business Plan Pt. 1",
    link: "https://www.youtube.com/embed/bXElpFqilII",
    description:
      "In today's episode we are focusing on our EPIC business plan for 2020!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "381",
    title: "Epic 2020 Business Plan Pt. 2",
    link: "https://www.youtube.com/embed/7TDRZKkFdBw",
    description:
      "In today's episode we are focusing on our EPIC 2020 business plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },

  {
    id: "382",
    title: "Epic 2020 Business Plan Pt. 3",
    link: "https://www.youtube.com/embed/2zpfLBqPaAk",
    description:
      "In today's episode we are going over our EPIC 2020 business plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "383",
    title: "Epic 2020 Business Plan Pt. 4",
    link: "https://www.youtube.com/embed/ozNmX6MQjNA",
    description:
      "In today's episode we are talking about our EPIC 2020 business plan. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "384",
    title: "Epic 2020 Business Plan Pt. 5",
    link: "https://www.youtube.com/embed/ikRktEOG6Uo",
    description:
      "Welcome to Breakthrough Real Estate Daily Tidbits!!, In today's episode we are focusing on your Epic 2020 Business Plan, finishing it up today. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "385",
    title: "Happy Thanksgiving!",
    link: "https://www.youtube.com/embed/EK4iE97p6h4",
    description:
      "In today's episode we are wishing you an Happy and Safe Thanksgiving!!,Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "386",
    title: 'Talking About Past Clients',
    link: "https://www.youtube.com/embed/qIw4f08KhpM",
    description:
      "In today's episode we are focusing on our Past Clients, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "387",
    title: 'Brand Awareness',
    link: "https://www.youtube.com/embed/StEaL4F6oJk",
    description:
      "In today's episode we are focusing on our Brand Awareness. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "388",
    title: 'Back To The Basics',
    link: "https://www.youtube.com/embed/OaxL0rNA5BM",
    description:
      'In today\'s episode we are going back to the basics. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "389",
    title: 'What Other Services Can You Provide?',
    link: "https://www.youtube.com/embed/u_5JLeJUEHE",
    description:
      'In today\'s episode we are focusing on what other services we can provide? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "390",
    title: 'Standards',
    link: "https://www.youtube.com/embed/dWROTRi5e6w",
    description:
      'In today\'s episode we are focusing on our Standards, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!',
  },
  {
    id: "391",
    title: "Helping Others Makes Us Happy",
    link: "https://www.youtube.com/embed/3UJuX17f73o",
    description:
      "In today's episode we are focusing on others... Because it also makes us happy. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "392",
    title: "Stay In Front Of People",
    link: "https://www.youtube.com/embed/epajaAtWZmI",
    description:
      "In today's episode we are focusing on Staying In Front Of People, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!",
  },
  {
    id: "393",
    title: "Thank You",
    link: "https://www.youtube.com/embed/Jy96N0DUTvE",
    description:
      'In today\'s episode we are talking about "Thank You\'s",Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!'
  },
  {
    id: "394",
    title: "Focus On The People",
    link: "https://www.youtube.com/embed/zqoDGUYxjuU",
    description:
      "In today's episode we are focusing on the people. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "395",
    title: "What Makes You Unique?",
    link: "https://www.youtube.com/embed/ND0-RH3T6PI",
    description:
      "In today's episode we are talking about what makes you unique, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "396",
    title: 'What Are Your Core Values?',
    link: "https://www.youtube.com/embed/CF_gJEL7LIw",
    description:
      "In today's episode we are asking, what are your core values? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "397",
    title: 'Why Do So Many Real Estate Professionals Fail?',
    link: "https://www.youtube.com/embed/QKf1CpLrVWE",
    description:
      "In today's episode we are talking about why so many real estate professionals fail. Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "398",
    title: 'What Do You Look For In A Brokerage?',
    link: "https://www.youtube.com/embed/DkGqCijaM6k",
    description:
      "In today's episode we are asking, what do you look for in a brokerage? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "399",
    title: 'Merry Christmas',
    link: "https://www.youtube.com/embed/qzoP7jgfU-M",
    description:
      "In today's episode we are wishing you a Merry Christmas!, Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },
  {
    id: "400",
    title: "Which Is Better, Team or Individual Agent?",
    link: "https://www.youtube.com/embed/nFPPjQUqmdU",
    description:
      "Welcome to Breakthrough Real Estate Daily Tidbits!!, In today's episode we are asking, which is better, team or individual agent? Daily tidbits are designed and meant to help start your day on the best note by offering motivational, entertaining and educational content each morning. If you find these tidbits helpful and awesome, please share them. Help us build our community and spread the word. It is so appreciated!"
  },

  // --------------- Start from Episode 351 ---------------
  
  // {
  //   id:"",
  //   title:"",
  //   link:"",
  //   description:""
  // }
];

export default sampleData;
