import Axios from "axios";

let baseURL="https://breakthroughpropertymanagement.com/";
 

const getData=()=> {
    return Axios.get(baseURL+"BrepApi/api-fetch-all-trench.php");
}

const postData=(newRecord)=> {
  return Axios.post(baseURL+"BrepApi/api-insert-oop.php", newRecord,{headers:{
    'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
  }});
}

const createEpisode=(detail)=>{
  return Axios.post(baseURL+"BrepApi/api-insert-trench.php",detail)
}

export { getData, postData, createEpisode };