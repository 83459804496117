import React from "react";

function About() {
  return (
    <div>
      <main id="main">
        <section
          class="hero-banner-sect"
          style={{ minHeight: "20vh" }}
          id="banner-sect"
        >
          <div class="hero-banner-sect-wrapper">
            <div class="container">
              <div className="video-container" style={{ zIndex: "-10" }}>
                <video autoPlay muted loop id="myVideo">
                  <source src="assets/videos/About.mp4" type="video/mp4" />
                </video>
              </div>

              <div class="row" style={{ color: "black" }}>
                <div class="col banner-head-col">
                  <div class="banner-head-wrapper my-5 pt-5">
                    <h2 class="banner-head-text pb-5">
                      <span>About Us</span>
                      <br />
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-about">
          <div class="container">
            <div class="row">
              <div class="col-md-12 section-t8 position-relative">
                <div class="row">
                  <div class="col-lg-2  d-none d-lg-block position-relative">
                    <div class="title-vertical d-flex justify-content-start">
                      <span>Read More About Us</span>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-8 section-md-t3">
                    <div class="title-box-d">
                      <h3 class="title-d">
                        A<span class="color-d"> Brief</span> History
                      </h3>
                    </div>
                    <p class="color-text-a">
                      We started PM Services nearly a decade ago with the idea,
                      "Owners should receive 100% of their rents each month" and
                      went from there. With no trust account, owners can receive
                      their rents within 3 business days. AND this service can
                      save you money each month. BREAKTHROUGH!
                    </p>
                    <p class="color-text-a">
                      Next we thought, "Management should be paid based upon
                      performance and attaining the owners goals", NOT just
                      taking a fee each month.... So we developed our
                      performance based value model, perfect for multi unit
                      complexes, remodels, new purchases, and so much more. We
                      only get paid when you hit your milestones. BREAKTHROUGH!!
                      Message us or call to find out more!
                    </p>
                    <p className="color-text-a">
                      Our marketing is second to none, this ensures your
                      property gets in front of the maximum amount of
                      prospective tenants. This means you will have the most
                      applications. More applications mean the best tenants.
                      This ensures the highest rents for you and your properties
                      and the best care and maintenance from all parties
                      involved. BREAKTHROUGH!!!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container w-75 mx-auto video-container mt-md-5  ">
          <iframe
            width="100%"
            height="576"
            src="https://www.youtube.com/embed/2Jp7iQ2SlEg"
            title="Property Management Services from Breakthrough Real Estate & Property Management."
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </section>
      </main>
    </div>
  );
}

export default About;
