import React from 'react'

function Forclosures() {
  return (
    <div>
        <h1>This is Forclosures Component</h1>
    </div>
  )
}

export default Forclosures