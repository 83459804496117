import React from 'react'

function Search() {
  return (
    <div>
        <h1>This is Search Component</h1>
    </div>
  )
}

export default Search